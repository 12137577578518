import React from "react";
import Main from "./components/Main";
import "./Home.scss";

const Home = () => {

  return (
    <div className="home">
      <Main/>
      {/* <Player/> */}
    </div>
  );
};

export default Home;
